import { useEffect, useMemo } from 'react'
import queryString from 'query-string'

import * as cloudLib from 'global/lib/cloud/cloud'
import { config } from 'global/lib/config'

const ONPREM = 'onprem'
const PRODUCT_DF = 'dp'

export default function useSignupLogic(): void {
  const isDomainFraudSignup = useMemo(() => {
    const historyState = window.history.state
    const prevSearchParams = historyState?.usr?.prevSearchParams
    if (prevSearchParams) {
      const parsedParams = queryString.parse(prevSearchParams)
      return parsedParams.provider === ONPREM || parsedParams.product === PRODUCT_DF
    }
    return false
  }, [])

  useEffect(() => {
    if (isDomainFraudSignup) {
      cloudLib.bccSignin(config.SCAN_TYPES.DFP)
    } else {
      cloudLib.bccSignin(config.SCAN_TYPES.SENTINEL)
    }
  }, [isDomainFraudSignup])
}
